<template>
  <div>
    <MyHeader :title="$t('lang.menu_patrolevent')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />

    <van-pull-refresh v-model="loading" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
        direction="down" @load="onLoad">
        <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.eventName"
          :icon="mData.isNormal ? 'checked' : 'warning'"
          :label="(mData.arriveTime != undefined ? $Tools.FormatDate1(mData.arriveTime) : '') + ' ' + mData.placeName"
          @click="onShowDetail(
              mData.type,
              mData.isNormal,
              mData.repairId,
              mData.type == 3 || mData.type == 4 || mData.type == 5 ? mData.logicName : ''
            )
            ">
          <template #right-icon>
            <van-tag :plain="mData.isNormal" :type="mData.isNormal ? 'success' : 'danger'"
              :color="((mData.type == 3 || mData.type == 4 || mData.type == 5) && mData.logicName == '' ? '#C0C0C0' : '')"
              size="medium" class="custtag">{{
                mData.type == 0
                ? (mData.numValue + mData.unitName + "(" + mData.minValue + "-" + mData.maxValue + ")")
                : (
                  (mData.type == 3 || mData.type == 4 || mData.type == 5)
                    ? (mData.logicName == "" ? $t("lang.hint_noattachment") : $t("lang.preview"))
                    : mData.logicName
                )
              }}</van-tag>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->

    <van-dialog v-model="showVideo" show-cancel-button :show-confirm-button="false" :cancel-button-text="$t('lang.close')"
      @close="onClose()">
      <video-player class="video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions"
        :playsinline="true" customEventName="customstatechangedeventname">
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.van-icon-checked {
  color: green;
}

.van-icon-warning {
  color: red;
}

.custtag {
  height: 24px;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import MyHeader from "@/components/Header.vue";
import { GetItemQuery, GetServerUrl } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      //showHint: false,

      backUrl: "",

      ServerUrl: "",
      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/query/index";
    else
      this.backUrl = "/query/appindex";
    //console.log("mounted");
  },

  activated() {
    //console.log("activated");
    if (this.$route.params.p) {
      this.page = 1;
      this.lstData = [];
      this.Query();
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: { title: this.$t("lang.menu_patrolevent"), f: "ItemQuery" },
      });
    },

    onRefresh() {
      this.page = 1;
      this.Query();
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        //this.showHint = true;
        return;
      }

      let that = this;
      that.loading = true;

      GetItemQuery(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
          eventName: p.mKeyword,
          queryType: p.mState,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              if (this.page == 1) that.lstData = ret.data;
              else that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            //that.showHint = false;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },

    onShowDetail(eventtype, isNormal, repairId, eventvalue) {
      if (eventtype == 3) {
        //图片
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          let imgurl = eventvalue.replace(
            RegExp("/app/images", "g"),
            this.ServerUrl + "app/images"
          );
          let mImage1 = imgurl.replace(RegExp("preview/", "g"), "").split("|");
          ImagePreview({
            images: mImage1,
            startPosition: 0,
          });
        }
      } else if (eventtype == 4 || eventtype == 5) {
        //录音,视频
        if (eventvalue == "") {
          this.$Toast(this.$t("lang.hint_noattachment"));
        } else {
          this.ServerUrl = GetServerUrl();
          this.playerOptions.sources[0].src = this.ServerUrl + eventvalue;
          this.showVideo = true;
        }
      } else {
        if (isNormal) return;
        if (repairId == 0) {
          this.$Toast(this.$t("lang.hint_noattachment"));
          return;
        }
        this.$router.push({
          name: "QueryDetail",
          query: {
            id: repairId,
          },
        });
      }
    },
  },
};
</script>
